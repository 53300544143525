import * as R from 'ramda';

import {
  ArrowType,
  SortDirectionsFullMap,
  SortDirectionsMap,
} from '@common/constants';
import { normalizeArray, normalizeDate } from '@common/utils/filter-helpers';
import { REVIEW_STATUS } from './statuses';

export * from './bulkEvents';
export * from './actionsTypes';
export * from './statuses';

export const namespace = 'DOCUMENT';

/**
 * Key for identify when load documents
 * on review page for every party.
 */
export const EveryPartyId = 'every';

export const APPLIES_TO_ALL_PROJECTS_KEY = 'appliesToAllProjects';

export const REVIEW_STATUS_LABEL_MAP = {
  [REVIEW_STATUS.Reviewed]: 'Reviewed',
  [REVIEW_STATUS.ToReview]: 'To review',
};

export const DocumentType = {
  acord_25: 'Certificate of Insurance',
  professional_license: 'Professional License',
  drivers_license: "Driver's License",
  w9: 'W-9',
  generic: 'Generic Document',
  contract: 'Contract',
};

export const SeverityLevel = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
};

export const SeverityLevelLabelMap = {
  [SeverityLevel.High]: 'High severity',
  [SeverityLevel.Medium]: 'Medium severity',
  [SeverityLevel.Low]: 'Low severity',
};

export const VerifiedVia = {
  PhoneCall: 'phoneCall',
  Email: 'email',
  Other: 'other',
};

export const VerificationStatusMap = {
  verified: 'Verified',
  notVerified: 'Not verified',
};

export const AssignedToPartyLabelMap = {
  assignedToParty: 'Assigned to a party',
  notAssignedToParty: 'Not assigned to any party',
};

export const DocumentFlagFields = [
  'addedOn',
  'severityLevel',
  'affectedSubjects',
  'notes',
];

export const DocumentVerificationFields = [
  'verifiedOn',
  'verifiedVia',
  'affectedSubjects',
  'notes',
];

export const DocumentIssuerFields = [
  'name',
  'address',
  'contactName',
  'phone',
  'email',
];

export const DocumentTypeByModuleId = {
  acord_25: 'evidenceOfInsurance',
};

export const documentSubject = {
  automobileLiabilityPolicyExpiration: 'Automobile Liability',
  commercialGeneralLiabilityPolicyExpiration: 'Commercial General Liability',
  excessOrUmbrellaLiabilityPolicyExpiration: 'Excess Or Umbrella Liability',
  workersCompensationAndEmployersLiabilityPolicyExpiration:
    'Workers Compensation And Employers Liability',
};

export const documentSubjectAbbreviation = {
  automobileLiability: {
    abbr: 'AL',
    name: 'Automobile Liability',
  },
  commercialGeneralLiability: {
    abbr: 'CGL',
    name: 'Commercial General Liability',
  },
  excessOrUmbrellaLiability: {
    abbr: 'UL',
    name: 'Excess Or Umbrella Liability',
  },
  workersCompensation: {
    abbr: 'WC',
    name: `Workers’ Compensation and Employer’s Liability`,
  },
  w9: {
    abbr: 'W9',
    name: 'W9',
  },
  businessLicense: {
    abbr: 'BL',
    name: 'Business License',
  },
  professionalLicense: {
    abbr: 'PL',
    name: 'Professional License',
  },
  driveLicense: {
    abbr: 'DL',
    name: `Driver’s License`,
  },
};

/**
 * @deprecated - use the one inside src/common/models
 */
export const acceptExtensionsArray = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/gif',
];

export const acceptExtensions = acceptExtensionsArray.join(', ');

export const EventType = {
  documentProcessed: 'document:processed',
  documentEmptyExtractedData: 'document:empty-extracted-data',
  partyComplianceUpdate: 'party:compliance-update',
  requestUpdate: 'request:update',
  eventLogUpdate: 'event:log:update',
  requirementsUpdate: 'requirements:update',
  partyComplianceProfileUpdate: 'party-compliance-profile:update',
  importPartiesSuccess: 'import:parties:success',
  importPartiesFail: 'import:parties:fail',
  assignProfilesSuccess: 'assign:profiles:success',
  assignProfilesFail: 'assign:profiles:fail',
  documentSignedSuccess: 'document:signed:success',
  documentSigning: 'document:signing',
  bulkArchiveDocumentFail: 'bulk:archive:documents:fail',
  bulkArchiveDocumentSuccess: 'bulk:archive:documents:success',
  bulkAssignDocumentsToPartyFail: 'bulk:assign:documents:party:fail',
  bulkAssignDocumentsToPartySuccess: 'bulk:assign:documents:party:success',
  bulkDetachDocumentsFromPartyFail: 'bulk:detach:documents:party:fail',
  bulkDetachDocumentsFromPartySuccess: 'bulk:detach:documents:party:success',
  bulkAssociateDocumentsToProjectFail: 'bulk:associate:documents:fail',
  bulkAssociateDocumentsToProjectSuccess: 'bulk:associate:documents:success',
  bulkDetachDocumentsFromProjectFail: 'bulk:detach:documents:project:fail',
  bulkDetachDocumentsFromProjectSuccess:
    'bulk:detach:documents:project:success',
  bulkReviewDocumentsFail: 'bulk:review:documents:fail',
  bulkReviewDocumentsSuccess: 'bulk:review:documents:success',
  bulkAddRequirementValueFail: 'bulk:add:requirement:fail',
  bulkAddRequirementValueSuccess: 'bulk:add:requirement:success',
  bulkDeleteDocumentsFail: 'bulk:delete:documents:fail',
  bulkDeleteDocumentsSuccess: 'bulk:delete:documents:success',
  bulkMatchRequirementsFail: 'bulk:match:requirements:documents:fail',
  bulkMatchRequirementsSuccess: 'bulk:match:requirements:documents:success',
  bulkMatchComplianceSuccess: 'bulk:match:compliance:success',
  bulkMatchComplianceFail: 'bulk:match:compliance:fail',
};

/**
 * Document filter fields.
 */
export const DocumentFilterFields = {
  Name: 'name',
  Type: 'type',
  AssociatedProjects: 'associatedProjects',
  Status: 'reviewStatus',
  Subject: 'subject',
  UploadDate: 'uploadDate',
  ExpirationDate: 'expirationDate',
  Flag: 'flag',
  RequirementId: 'requirementId',
  Verification: 'verification',
  AssignedParty: 'assignedParty',
};

/**
 * Document sort fields.
 */
export const DocumentSortFields = {
  Name: 'friendlyName',
  Party: 'party',
  Subject: 'subject',
  ExpirationDate: 'expirationDate',
  UploadDate: 'createdAt',
};

/**
 * Normalized document filters.
 */
export const NormalizedDocumentFilters = {
  [DocumentFilterFields.Name]: {
    label: 'Name',
    normalizeValue: (value) => value,
  },
  [DocumentFilterFields.Type]: {
    label: 'Type',
    normalizeValue: normalizeArray,
  },
  [DocumentFilterFields.AssociatedProjects]: {
    label: 'Associated projects',
    normalizeValue: normalizeArray,
  },
  [DocumentFilterFields.Status]: {
    label: 'Status',
    normalizeValue: normalizeArray,
  },
  [DocumentFilterFields.Subject]: {
    label: 'Subject',
    normalizeValue: normalizeArray,
  },
  [DocumentFilterFields.UploadDate]: {
    label: 'Upload date',
    normalizeValue: normalizeDate,
    isRangeFilter: true,
  },
  [DocumentFilterFields.ExpirationDate]: {
    label: 'Expiration',
    normalizeValue: normalizeDate,
    isRangeFilter: true,
  },
  [DocumentFilterFields.Flag]: {
    label: 'Flag',
    normalizeValue: normalizeArray,
  },
  [DocumentFilterFields.RequirementId]: {
    label: 'Requirement ID',
    normalizeValue: (value) => value,
  },
  [DocumentFilterFields.Verification]: {
    label: 'Verification',
    normalizeValue: normalizeArray,
  },
  [DocumentFilterFields.AssignedParty]: {
    label: 'Party',
    normalizeValue: normalizeArray,
  },
};

/**
 * Data for documents sort.
 */
export const DocumentsSortOptions = [
  {
    label: 'Name',
    iconType: ArrowType.Up,
    field: DocumentSortFields.Name,
    direction: SortDirectionsMap.asc,
    helpText: 'A › Z',
  },
  {
    label: 'Name',
    iconType: ArrowType.Down,
    field: DocumentSortFields.Name,
    direction: SortDirectionsMap.desc,
    helpText: 'Z › A',
  },
  {
    label: 'Party',
    iconType: ArrowType.Up,
    field: DocumentSortFields.Party,
    direction: SortDirectionsMap.asc,
    helpText: 'A › Z',
  },
  {
    label: 'Party',
    iconType: ArrowType.Down,
    field: DocumentSortFields.Party,
    direction: SortDirectionsMap.desc,
    helpText: 'Z › A',
  },
  {
    label: 'Subject',
    iconType: ArrowType.Up,
    field: DocumentSortFields.Subject,
    direction: SortDirectionsMap.asc,
    helpText: 'A › Z',
  },
  {
    label: 'Subject',
    iconType: ArrowType.Down,
    field: DocumentSortFields.Subject,
    direction: SortDirectionsMap.desc,
    helpText: 'Z › A',
  },
  {
    label: 'Expiration',
    iconType: ArrowType.Up,
    field: DocumentSortFields.ExpirationDate,
    direction: SortDirectionsMap.asc,
    helpText: 'close › far',
  },
  {
    label: 'Expiration',
    iconType: ArrowType.Down,
    field: DocumentSortFields.ExpirationDate,
    direction: SortDirectionsMap.desc,
    helpText: 'far › close',
  },
  {
    label: 'Upload date',
    iconType: ArrowType.Up,
    field: DocumentSortFields.UploadDate,
    direction: SortDirectionsMap.asc,
    helpText: 'less recent › more recent',
  },
  {
    label: 'Upload date',
    iconType: ArrowType.Down,
    field: DocumentSortFields.UploadDate,
    direction: SortDirectionsMap.desc,
    helpText: 'more recent › less recent',
  },
];

/**
 * Get sort label by field and direction.
 */
export const getSortLabelData = (field, direction) =>
  R.compose(
    R.find(R.propEq('direction', direction)),
    R.filter(R.propEq('field', field)),
  )(DocumentsSortOptions);

export const getDocumentsColumns = ({
  sortField,
  sortDirection,
  isComplianceRequirementsAddOnEnabled,
}) => {
  const order = SortDirectionsFullMap[sortDirection];

  return [
    {
      title: 'Document name',
      dataIndex: DocumentSortFields.Name,
      key: DocumentSortFields.Name,
      sorter: true,
      sortOrder: sortField === DocumentSortFields.Name ? order : false,
    },
    {
      title: 'Party',
      dataIndex: DocumentSortFields.Party,
      key: DocumentSortFields.Party,
      sorter: true,
      sortOrder: sortField === DocumentSortFields.Party ? order : false,
      width: 150,
    },
    {
      title: 'Subject',
      dataIndex: DocumentSortFields.Subject,
      key: DocumentSortFields.Subject,
      sorter: true,
      sortOrder: sortField === DocumentSortFields.Subject ? order : false,
      width: 110,
      isVisible: isComplianceRequirementsAddOnEnabled,
    },
    {
      title: 'Expiration',
      dataIndex: DocumentSortFields.ExpirationDate,
      key: DocumentSortFields.ExpirationDate,
      sorter: true,
      sortOrder:
        sortField === DocumentSortFields.ExpirationDate ? order : false,
      width: 110,
    },
    {
      title: 'Upload date',
      dataIndex: DocumentSortFields.UploadDate,
      key: DocumentSortFields.UploadDate,
      sorter: true,
      sortOrder: sortField === DocumentSortFields.UploadDate ? order : false,
      width: 110,
    },
    {
      dataIndex: 'menu',
      key: 'menu',
      width: 100,
    },
  ].filter((column) => column.isVisible !== false);
};

export const ConnectedLicenseType = {
  NPN: 'NPN',
};

export const ConnectedLicenseStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  INVALID: 'invalid',
};

export const ConnectedLicenseNames = {
  NPN: 'National Producer',
};

/**
 * OTHER EXPORTS FROM CONSTANTS FOLDER
 */
export * from './carriers';
